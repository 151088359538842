import React, { useEffect, useRef } from 'react';
import cn from 'clsx';

import { Icon, IconName } from '../Icon';
import styles from './Notification.module.scss';

type NotificationSeverity =
  | 'info'
  | 'warning'
  | 'error'
  | 'submitting'
  | 'success';

export interface NotificationItem {
  id: string;
  isVisible?: boolean;
  message: React.ReactNode;
  duration?: number;
  severity?: NotificationSeverity;
}

interface NotificationProps extends NotificationItem {
  className?: string;
  onClose?: (id: string) => void;
  onAfterClose?: (id: string) => void;
}

const severityIcons: {
  [key in NotificationSeverity]: IconName;
} = {
  error: 'stop',
  warning: 'info',
  info: 'info',
  submitting: 'loading',
  success: 'check',
};

export const Notification: React.FC<NotificationProps> = ({
  id,
  className,
  message,
  isVisible = false,
  duration = 5000,
  severity = 'info',
  onAfterClose,
  onClose,
}) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentElement = elementRef?.current;

    const handleAnimationEnd = (e: AnimationEvent) => {
      if (e.animationName.includes('hideDown')) {
        onAfterClose?.(id);
      }
    };

    const handleAutoClose = () => {
      onClose?.(id);
    };

    currentElement?.addEventListener('animationend', handleAnimationEnd);

    const timerId = setTimeout(handleAutoClose, duration);

    return () => {
      clearTimeout(timerId);
      currentElement?.removeEventListener('animationend', handleAnimationEnd);
    };
  });

  const handleClose = () => {
    onClose?.(id);
  };

  return (
    <div
      ref={elementRef}
      className={cn(styles.nofication, styles[severity], className, {
        [styles.show]: isVisible,
        [styles.hide]: !isVisible,
      })}
    >
      <Icon className={styles.icon} name={severityIcons[severity]} />
      <div className={styles.content}>
        <p className={styles.severity}>{severity}</p>
        <p className={styles.message}>{message}</p>
      </div>
      <button type="button" className={styles.closeBtn} onClick={handleClose}>
        <Icon name="close" />
      </button>
    </div>
  );
};
