import React from 'react';
import clsx from 'clsx';

import { ReactComponent as LogoImage } from 'assets/logo.svg';

import { LabelPlate } from '../UIKit';
import { WalletContainer } from '../WalletContainer';

import styles from './Header.module.scss';

interface HeaderProps {
  className?: string;
}

export const Header: React.FC<HeaderProps> = ({ className }) => (
  <header className={clsx(styles.root, className)}>
    <div className={styles.leftSide}>
      <LogoImage className={styles.logo} />
      <LabelPlate>Origins Testnet</LabelPlate>
    </div>
    <div className={styles.rightSide}>
      <WalletContainer />
    </div>
  </header>
);
