import React from 'react';
import clsx from 'clsx';

import { Header } from '../Header';

import styles from './Layout.module.scss';

interface LayoutProps {
  children?: React.ReactNode;
  className?: string;
}

export const Layout: React.FC<LayoutProps> = ({ children, className }) => (
  <>
    <video className={styles.bgMedia} autoPlay loop muted>
      <source src="/video/background-animation.mp4" type="video/mp4" />
      <track kind="descriptions" />
    </video>
    <div className={styles.root}>
      <div className={styles.container}>
        <Header className={styles.header} />
        <main className={clsx(styles.main, className)}>{children}</main>
      </div>
    </div>
  </>
);
