import React from 'react';
import { WagmiConfig } from 'wagmi';
import wagmiConfig from 'config/wagmiConfig';

import { Layout } from 'components/Layout';
import { FloatingWindow } from 'components/FloatingWindow';
import { NotificationProvider } from 'components/UIKit/Notification';

import styles from './App.module.scss';

export const App: React.FC = () => (
  <WagmiConfig config={wagmiConfig}>
    <NotificationProvider>
      <Layout className={styles.container}>
        <FloatingWindow className={styles.window} />
      </Layout>
    </NotificationProvider>
  </WagmiConfig>
);
