import { parseUnits, formatUnits } from 'ethers';

import { appConfig } from 'config';

const { decimals } = appConfig.currency;

function formatOmni(value?: bigint): string {
  if (value !== undefined) {
    return String(parseFloat(formatUnits(value, decimals)));
  }

  return '';
}

function parseOmni(value: string): bigint {
  return parseUnits(value, decimals);
}

export { formatOmni, parseOmni };
