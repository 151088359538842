import React from 'react';
import clsx from 'clsx';

import { copyText } from 'utils/copyText';

import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as DisconnectIcon } from 'assets/icons/disconnect.svg';

import styles from './WalletDropdown.module.scss';

type WalletDropdownProps = {
  address: string;
  setDropdownOpen: (state: boolean) => void;
  disconnect: () => void;
};

export const WalletDropdown: React.FC<WalletDropdownProps> = ({
  address,
  setDropdownOpen,
  disconnect,
}) => {
  const handleDisconnect = () => {
    disconnect();
    setDropdownOpen(false);
  };

  const handleCopy = () => {
    copyText(address);
    setDropdownOpen(false);
  };

  return (
    <div className={styles.root}>
      <button className={styles.option} onClick={handleCopy}>
        <CopyIcon className={styles.icon} />
        Copy address
      </button>
      <div className={styles.line} />
      <button
        className={clsx(styles.option, styles.optionRed)}
        onClick={handleDisconnect}
      >
        <DisconnectIcon className={styles.icon} />
        Disconnect wallet
      </button>
    </div>
  );
};
