import React, { useState } from 'react';
import clsx from 'clsx';
import { useAccount, useConnect, useDisconnect, useChainId } from 'wagmi';

import { appConfig } from 'config';
import { shortenAddress } from 'utils';
import { ReactComponent as MetamaskLogo } from 'assets/metamask.svg';

import { Button, Dropdown } from '../UIKit';
import { WalletDropdown } from '../WalletDropdown';

import styles from './WalletContainer.module.scss';

export const WalletContainer: React.FC = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { address, isConnected } = useAccount();
  const { connect, connectors } = useConnect();
  const { disconnect } = useDisconnect();
  const chainId = useChainId();

  const handleConnect = () => {
    connect({
      connector: connectors[0],
      chainId: appConfig.chainId,
    });
  };

  if (isConnected && address) {
    return (
      <>
        <div
          className={clsx(styles.tile, styles.circle, {
            [styles.circleConnected]: chainId === appConfig.chainId,
          })}
        >
          Omni Testnet
        </div>

        <Dropdown
          isOpen={dropdownOpen}
          onOpenUpdate={setDropdownOpen}
          parent={
            <button
              type="button"
              className={clsx(styles.tile, styles.dropdownHeader)}
            >
              <MetamaskLogo />
              {shortenAddress(address)}
            </button>
          }
        >
          <WalletDropdown
            disconnect={disconnect}
            address={address}
            setDropdownOpen={setDropdownOpen}
          />
        </Dropdown>
      </>
    );
  }

  return <Button onClick={handleConnect}>Connect Wallet</Button>;
};
