import React, { useEffect } from 'react';
import { usePopper } from 'react-popper';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

export interface BaseDropdownProps {
  isOpen?: boolean;
  parent: React.ReactElement;
}

export interface DropdownProps extends BaseDropdownProps {
  children: React.ReactElement;
  onOpenUpdate?: (open: boolean) => void;
  className?: string;
}

export const Dropdown: React.FC<DropdownProps> = ({
  isOpen,
  parent,
  children,
  onOpenUpdate,
  className,
}) => {
  const [open, setOpen] = React.useState(isOpen);
  const [popperElement, setPopperElement] = React.useState<HTMLElement>();
  const [referenceElement, setReferenceElement] = React.useState<HTMLElement>();

  function openCloseDropdown() {
    setOpen(!open);

    if (onOpenUpdate) {
      onOpenUpdate(!open);
    }
  }

  useOnClickOutside(
    [
      { current: popperElement } as React.RefObject<HTMLElement>,
      { current: referenceElement } as React.RefObject<HTMLElement>,
    ],
    openCloseDropdown,
  );

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    function mouseMoveListener(e: MouseEvent) {
      const path = e.composedPath();

      setOpen(
        path.includes(referenceElement as EventTarget) ||
          path.includes(popperElement as EventTarget),
      );
    }

    return () => document.removeEventListener('mousemove', mouseMoveListener);
  }, [popperElement, referenceElement]);

  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
  );

  const parentEl = React.cloneElement(parent, {
    ref: setReferenceElement as React.LegacyRef<unknown>,
    onClick: openCloseDropdown,
  });

  function getDropdown() {
    return (
      <div
        ref={setPopperElement as React.LegacyRef<HTMLDivElement>}
        style={{ ...popperStyles.popper, zIndex: 100 }}
        {...attributes.popper}
        className={className}
      >
        {children}
      </div>
    );
  }

  return (
    <>
      {parentEl}
      {open && getDropdown()}
    </>
  );
};
