import React from 'react';
import clsx from 'clsx';

import styles from './Button.module.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
  variant?: 'primary' | 'secondary';
}

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  variant = 'primary',
  ...restProps
}) => (
  <button
    className={clsx(
      styles.root,
      styles[variant],
      { [styles.disabled]: restProps.disabled },
      className,
    )}
    {...restProps}
  >
    {children}
  </button>
);
