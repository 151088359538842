import React from 'react';

import * as icons from 'assets/icons';

export type IconName = keyof typeof icons;

export type IconProps = React.SVGProps<SVGSVGElement> & {
  name: IconName;
};

export const Icon: React.FC<IconProps> = ({ name, ...rest }) => {
  const Component = icons[name];

  if (!Component) {
    return null;
  }

  return <Component {...rest} />;
};
