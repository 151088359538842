import React from 'react';

import styles from './LabelPlate.module.scss';

interface LabelPlateProps {
  children?: React.ReactNode;
}

export const LabelPlate: React.FC<LabelPlateProps> = ({ children }) => (
  <div className={styles.root}>{children}</div>
);
