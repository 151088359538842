import React from 'react';
import clsx from 'clsx';

import styles from './FloatingWindow.module.scss';

interface FloatingWindowProps {
  className?: string;
}

export const FloatingWindow: React.FC<FloatingWindowProps> = ({
  className,
}) => (
  <div className={clsx(styles.root, className)}>
    {/*
      <Tabs
        tabs={[
          { id: '0', title: 'Deposit', content: <DepositContent /> },
          { id: '1', title: 'Withdraw', content: <WithdrawContent /> },
          { id: '2', title: 'Claim', content: <ClaimContent /> },
        ]}
      />
    */}

    <p>
      Omni Origins closed on August 8th, check out our recap of the growth and
      next steps for Testnet 2{' '}
      <a href="https://blog.omni.network/omni-origins-growth-recap-and-preparation-for-testnet-v2/">
        here.
      </a>
    </p>
  </div>
);
