export function shortenAddress(
  address: string,
  charsBefore = 5,
  charsAfter = 4,
): string {
  const parsedAddress: string | false = address;

  if (!parsedAddress) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return `${parsedAddress.substring(
    0,
    charsBefore,
  )}...${parsedAddress.substring(address.length - charsAfter)}`;
}
