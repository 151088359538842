import { Chain, configureChains, createConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { appConfig } from './index';

export const omniLocalnet: Chain = {
  id: 31337,
  name: 'Omni Localnet',
  network: 'omnilocalnet',
  nativeCurrency: {
    name: appConfig.currency.name,
    symbol: appConfig.currency.symbol,
    decimals: appConfig.currency.decimals,
  },
  rpcUrls: {
    default: { http: ['http://127.0.0.1:8545'] },
    public: { http: ['http://127.0.0.1:8545'] },
  },
  testnet: true,
};

export const omniTestnet: Chain = {
  id: appConfig.testnet.chainId,
  name: 'Omni Testnet',
  network: 'omnitestnet',
  nativeCurrency: {
    name: appConfig.currency.name,
    symbol: appConfig.currency.symbol,
    decimals: appConfig.currency.decimals,
  },
  rpcUrls: {
    default: { http: [appConfig.testnet.rpc] },
    public: { http: [appConfig.testnet.rpc] },
  },
  blockExplorers: {
    default: {
      name: 'Omni Block Explorer',
      url: appConfig.testnet.explorer,
    },
  },
  testnet: true,
};

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [omniLocalnet, omniTestnet],
  [publicProvider()],
);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [new MetaMaskConnector({ chains })],
  publicClient,
  webSocketPublicClient,
});

export default wagmiConfig;
