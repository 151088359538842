import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Notification, NotificationItem } from './Notification';

import styles from './NotificationProvider.module.scss';

interface NotificationContextProps {
  notify: (data: Omit<NotificationItem, 'id' | 'isVisible'>) => string;
  hideNotification: (id: string) => void;
}

const NotificationContext = React.createContext<NotificationContextProps>({
  notify: () => {
    throw new Error('The method notify is not implemented!');
  },
  hideNotification: () => {
    throw new Error('The method hideNotification is not implemented!');
  },
});

interface NotificationProviderProps {
  children: React.ReactNode;
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<
    (NotificationItem & { id: string })[]
  >([]);

  const handleAddNotification = useCallback(
    (data: Omit<NotificationItem, 'id' | 'isVisible'>): string => {
      const id = `${Date.now()}${Math.random()}`;

      setNotifications((prev) => [{ ...data, isVisible: true, id }, ...prev]);

      return id;
    },
    [],
  );

  const handleHideNotification = useCallback((id: string) => {
    setNotifications((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return { ...item, isVisible: false };
        }

        return item;
      }),
    );
  }, []);

  const handleRemoveNotification = useCallback((id: string) => {
    setNotifications((prev) => prev.filter((item) => item.id !== id));
  }, []);

  return (
    <NotificationContext.Provider
      value={useMemo(
        () => ({
          notify: handleAddNotification,
          hideNotification: handleHideNotification,
        }),
        [handleAddNotification, handleHideNotification],
      )}
    >
      {children}
      <div className={styles.notifications}>
        {notifications.map((notification) => (
          <Notification
            key={notification.id}
            {...notification}
            onClose={handleHideNotification}
            onAfterClose={handleRemoveNotification}
          />
        ))}
      </div>
    </NotificationContext.Provider>
  );
};

export const useNotify = () => useContext(NotificationContext);
