// eslint-disable-next-line arrow-body-style
const env = (key: string): string => {
  // eslint-disable-next-line no-underscore-dangle
  return (window.__APP_CONFIG__[key] || process.env[key]) as string;
};

export type AppConfig = {
  name: string;
  chainId: number;
  faucetUrl: string;
  contracts: {
    optimizer: `0x${string}`;
  };
  testnet: {
    chainId: number;
    explorer: string;
    rpc: string;
  };
  currency: {
    symbol: string;
    name: string;
    decimals: number;
  };
  farmToken: {
    address: string;
    decimals: number;
    image: string;
    symbol: string;
  };
};

export const appConfig: AppConfig = {
  name: env('REACT_APP_OMNI_NAME'),
  chainId: parseInt(env('REACT_APP_CHAIN_ID'), 10),
  faucetUrl: env('REACT_APP_FAUCET_URL'),
  contracts: {
    optimizer: env('REACT_APP_OPTIMIZER_ADDRESS') as `0x${string}`,
  },
  testnet: {
    chainId: parseInt(env('REACT_APP_TESTNET_CHAIN_ID'), 10),
    explorer: env('REACT_APP_TESTNET_EXPLORER'),
    rpc: env('REACT_APP_TESTNET_RPC'),
  },
  currency: {
    symbol: env('REACT_APP_TESTNET_CURRENCY_SYMBOL'),
    name: env('REACT_APP_TESTNET_CURRENCY_NAME'),
    decimals: parseInt(env('REACT_APP_TESTNET_CURRENCY_DECIMALS'), 10),
  },
  farmToken: {
    address: env('REACT_APP_FARM_TOKEN_ADDRESS'),
    decimals: parseInt(env('REACT_APP_FARM_TOKEN_DECIMALS'), 10),
    image: env('REACT_APP_FARM_TOKEN_IMAGE'),
    symbol: env('REACT_APP_FARM_TOKEN_SYMBOL'),
  },
};
